import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Navigation from "../components/navigation";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      menuBar: file(relativePath: { eq: "header_open.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "header_close.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "logo_color.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden mdUp>
        <Box
          width="95%"
          m={"auto"}
          left={0}
          right={0}
          position="fixed"
          top={"16px"}
          zIndex="99"
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={3}>
              <Link to="/">
                <Img fluid={data.logo.childImageSharp.fluid} />
              </Link>
            </Grid>
            <Grid item xs={2}>
              {isOpen ? (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "50%", maxWidth: "40px" }}>
                    <Img fluid={data.close.childImageSharp.fluid} />
                  </Box>
                </Button>
              ) : (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "70%", maxWidth: "40px" }}>
                    <Img fluid={data.menuBar.childImageSharp.fluid} />
                  </Box>
                </Button>
              )}
            </Grid>
          </Grid>
          <Drawer
            anchor={"left"}
            open={isOpen}
            style={{ width: "100%" }}
            transitionDuration="1500"
          >
            <Box width="100vw">
              <Box width="90%" margin="auto" pt="32px">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={6}></Grid>
                  <Grid item xs={2}>
                    {isOpen ? (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto"
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "80%", maxWidth: "40px" }}>
                          <Img fluid={data.close.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto"
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "70%", maxWidth: "40px" }}>
                          <Img fluid={data.menuBar.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box
                  width="100%"
                  maxWidth="400px"
                  m={"auto"}
                  display="flex"
                  alignItems="center"
                >
                  <Navigation isOpenMenu setOpen={setOpen} />
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Hidden>
    </>
  );
};

export default Header;
