import React from "react";
import { Box, Hidden, Button } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import WrapTypo from "../components/WrapTypo";
import "../css/base.css";

const Navigation = props => {
  const { isOpenMenu = false, setOpen } = props;
  const data = useStaticQuery(graphql`
    query {
      nav_tsuri: file(relativePath: { eq: "nav_tsuri.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_tomari: file(relativePath: { eq: "nav_tomari.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_map: file(relativePath: { eq: "nav_map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_work: file(relativePath: { eq: "nav_work.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo_color: file(relativePath: { eq: "logo_color.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const navigations = [
    {
      link: "/#work",
      img: data.nav_work.childImageSharp.fluid
    },
    {
      link: "/#tsuri",
      img: data.nav_tsuri.childImageSharp.fluid
    },
    {
      link: "/#hotel",
      img: data.nav_tomari.childImageSharp.fluid
    },
    {
      link: "/#map",
      img: data.nav_map.childImageSharp.fluid
    }
  ];
  return (
    <>
      <Hidden smDown>
        <Box width="15%" position="fixed" right={"2.5%"} zIndex={99}>
          <Box width="100%" pt={4} m={"auto"}>
            <Img
              fluid={data.logo_color.childImageSharp.fluid}
              className="w100 tac"
            />
            <WrapTypo
              fs="18px"
              lh="200%"
              ls="4px"
              Tsukubrdgothic
              mt="40px"
              fw="500"
            >
              山形県庄内で、
              <br />
              釣りを楽しもう。
            </WrapTypo>
            <Box mt={[8, 4, 4, 12]} width="100%">
              {navigations.map(x => {
                return (
                  <Box mt={2}>
                    <Link to={x.link}>
                      <Img fluid={x.img} />
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        {isOpenMenu ? (
          <Box width="100%">
            <Box width="65%" pt={4} m={"auto"}>
              <Img
                fluid={data.logo_color.childImageSharp.fluid}
                className="w100 tac"
              />
              <WrapTypo
                fs="18px"
                lh="200%"
                ls="4px"
                Tsukubrdgothic
                mt="40px"
                fw="500"
              >
                山形県庄内で、
                <br />
                釣りを楽しもう。
              </WrapTypo>
              <Box mt={[8, 4, 4, 12]} width="100%">
                {navigations.map(x => {
                  return (
                    <Box mt={2}>
                      <Link
                        to={x.link}
                        onClick={() => setOpen(c => !c)}
                        className="w100"
                      >
                        <Img fluid={x.img} />
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Hidden>
    </>
  );
};

export default Navigation;
